.site-page {

    .box {
        max-width: calc(100vw - #{2 * $gap-3});
        margin: 0 auto;

        h2 {
            font-weight: 600;
            font-size: $font-size-6;
            margin-bottom: $gap-3;
        }
    }

    .box.properties {
        text-align: left;
    }


    .heading {
        padding: $gap-3;

        h1 {
            font-size: $font-size-8;
        }
    }

    .two-box {
        margin: 0 auto;
        display: grid;
        gap: $gap-3;
        max-width: 1000px;

        .box {
            text-align: left;
            padding: $gap-3;
            max-height: 500px;
            overflow-y: scroll;

            h3.box-heading {
                font-weight: 600;
                font-size: $font-size-4;
                margin-bottom: $gap-3;
            }

            .site-pdb, .site-metals, .site-residues {
                margin-bottom: $gap-3;

                .metal, .residue {
                    display: inline-block;
                    margin-right: $gap-2;
                    margin-bottom: $gap-2;
                    border: 1px solid $purple-3;
                    padding: $gap-2 $gap-3;
                    box-shadow: $box-shadow-2;
                    font-weight: 300;
                    cursor: pointer;
                }

                .metal.active, .residue.active {
                    background-color: #16a08520;
                }
            }

            .site-pdb {
                font-weight: 300;

                a {
                    font-weight: normal;
                }

                
            }

            p.secondary {
                display: block;
                text-align: left;
                padding: 0;
                padding-bottom: $gap-2;
            }

            .zinc-sites {
                border: 1px solid $purple-3;
                border-left: 0;
    
                .site {
                    margin-left: 0;
                }
            }

            p {
                font-style: italic;
                font-weight: 300;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 80%;
                padding: $gap-4 $gap-5;
                text-align: center;
                line-height: 2;
            }

            .group-info {
                font-weight: 300;
                margin-bottom: $gap-5;
            }
        }

        .box.group {
            max-height: 350px;
        }
    }

    .chains {
        padding: $gap-4;
        text-align: left;
        width: 100%;
        overflow: hidden;

        .pdb-chain {
            width: 100%;

            .site-count {
                font-size: $font-size-1;
            }

            .sequence {
                overflow-x: scroll;
                font-size: $font-size-1;
                color: $gray-4;
                padding-bottom: $gap-3;

                span {
                    font-family: monospace;
                    white-space: pre;
                }

                .binding {
                    color: $gray-10;
                    font-weight: bold;
                }
            }
        }
    }
    
}

@media (min-width: 600px) {
    .site-page {
        .box {
            h2 {
                font-size: $font-size-6;
            }
        }

        .heading {
            padding: $gap-4;

            h1 {
                font-size: $font-size-9;
            }
        }

        .two-box {
            .box {
                h3.box-heading {
                    font-size: $font-size-5;
                }
            }
        }

        .chains {

            .pdb-chain {
                .site-count {
                    font-size: $font-size-2;
                }

                .sequence {
                    font-size: $font-size-2;
                }
            }
        }
        
    }
}

@media (min-width: 800px) {
    .site-page {
        .two-box {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@media (min-width: 1032px) {
    .site-page {
        
        .box {
            max-width: 1000px;
        }
    }
}