.search-results-page {
    max-width: 1000px;
    
    .results {
        width: inherit;
        max-width: inherit;
        display: grid;
        grid-gap: $gap-3;
    }

    .box:first-child {
        margin-top: 0;;
    }
}