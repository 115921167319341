.properties {
    text-align: left;
    padding: $gap-3;
    display: grid;
    grid-gap: $gap-1;
    grid-template-columns: 1fr 1fr;

    .pdb-row {
        font-weight: 300;

        img {
            height: 10px;
        }

        .property {
            font-weight: 600;
            margin-right: 3px;
        }

        .species {
            font-style: italic;
        }

        a {
            font-weight: 400;
        }
    }

    .keywords {

        a {
            font-weight: 300;
        }

        a:after {
            content: ", ";
            color: $gray-8;
        }

        a:last-child:after {
            content: "";
        }
    }

    .full {
        grid-column: 1 / 3;
    }
}

@media (min-width: 600px) {
    .properties {
        padding: $gap-4;
        grid-gap: $gap-2;
    }
}
