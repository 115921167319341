.ngl-interface {
    padding: 0;
    text-align: left;
    overflow: hidden;

    .window {
        height: 450px;
        width: 100%;
        position: relative;

        p {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: $font-size-5;
            font-weight: 300;
            font-style: italic;
        }
    }

    .controls {
        border-top: 1px solid $purple-3;
        padding: $gap-3;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        background-color: $gray-1;

        .toggles {
            display: flex;
            justify-content: space-evenly;
        }

        .control {
            text-align: center;
            margin-bottom: $gap-5;

            label {
                margin-bottom: $gap-2;
                display: block;
                font-size: $font-size-2;
                font-weight: 600;
            }

            select {
                width: 180px;
                margin: auto;
            }

            .toggle-switch, .toggle-switch .toggle-knob {
                -moz-transition: all 0.2s ease-in;
                -webkit-transition: all 0.2s ease-in;
                -o-transition: all 0.2s ease-in;
                transition: all 0.2s ease-in;
            }

            .toggle-switch {
                text-align: left;
                height: 30px;
                width: 55px;
                display: inline-block;
                background-color: #ffffff;
                margin: 2px;
                margin-bottom: 5px;
                border-radius: 30px;
                cursor: pointer;
                border: solid 1px #d2d6de;
                box-shadow: inset 1px 1px 9px -3px rgba(4, 4, 4, 0.08), 1px 2px 6px -2px rgba(0, 0, 0, 0.01);

                .toggle-knob {
                    width: 28px;
                    height: 26px;
                    display: inline-block;
                    background-color: #ffffff;
                    border: solid 1px rgba(126, 126, 126, 0.07);
                    box-shadow: 0 1px 3px rgba(107, 106, 106, 0.26), 0 5px 1px rgba(107, 106, 106, 0.13);
                    border-radius: 26px;
                    margin: 1px 1px;
                }
            }

            .twitter-picker {
                margin: auto;

                input {
                    padding: inherit;
                    height: 30px !important;
                }
            }

            .toggle-switch.active {
                background-color: #77e189;
                border: solid 1px transparent;
            }

            .toggle-switch.active .toggle-knob {
                margin-left: 24px;
            }
        }

        
    }
}

@media (min-width: 700px) {
    .ngl-interface {
        .controls {
            flex-direction: row;
            padding: $gap-4;

            .control {
                margin-bottom: 0;

                label {
                    margin-bottom: $gap-3;
                    font-size: $font-size-3;
                }
            }

            .toggles {
                flex-grow: 1;
            }

            
        }
    }
}

@media (min-width: 900px) {
    .ngl-interface {
        display: grid;
        grid-template-columns: 1fr 350px;

        .window {
            max-width: calc(100vw - 380px);
        }

        .controls {
            flex-direction: column;
            border-top: none;
            border-left: 1px solid $purple-3;
            padding: $gap-3;

            .control {
                margin-bottom: $gap-4;

                label {
                    margin-bottom: $gap-2;
                    font-size: $font-size-2;
                }
            }

            .toggles {
                flex-grow: inherit;
            }
        }
    }
}