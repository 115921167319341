.sequence-result {

    width: inherit;
    max-width: inherit;

    .box {
        width: inherit;
        max-width: inherit;
        padding: 0;
        background-color: $gray-1;
        font-size: $font-size-2;

        .info {
            display: flex;
            border-bottom: 1px solid $purple-3;
            align-items: stretch;
            background-color: white;
            
            h2 {
                font-size: $font-size-5;
                white-space: nowrap;
                border-right: 1px solid $purple-3;
            }

            h2, .metric, .title {
                padding: $gap-1;
                display: flex;
                align-items: center;
            }

            .metric {
                border-right: 1px solid $purple-3;
                white-space: nowrap;
            }

            .title {
                display: none;

                span {
                    font-style: italic;
                    display: contents;
                }
            }

        }

        .sequence {
            padding: $gap-3;
            text-align: left;
            background-color: white;
            font-size: $font-size-2;
            overflow-x: scroll;

            .query, .midline, .hit, .out, .in {
                font-family: monospace;
                white-space: pre;
            }

            .out {
                color: $gray-3;
            }
        }

        
    }
}


@media (min-width: 600px) {
    .sequence-result {

        .box {
            font-size: $font-size-3;

            .info {
                .title {
                    display: flex;
                }
            }
        }
        
    }
}