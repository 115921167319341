.data-page {
    max-width: 1000px;

    .box {

        p {
            line-height: 2;
        }
    }

    .charts {
        width: 100%;
        max-width: 1000px;
        margin: 0px auto;
        margin-bottom: $gap-3;

        .box {
            margin: 0px;
            display: inline-block;
            width: 100%;
            margin-bottom: calc(#{$gap-3} - 2px);
        }

        .box:nth-child(6) {
            margin-bottom: 0;
        }
    }
}

@media (min-width: 800px) {
    .data-page {
        .charts {
            .box {
                width: calc(50% - #{$gap-3 / 2});
            }

            .box:nth-child(odd) {
                margin-right: $gap-3 / 2;
            }

            .box:nth-child(even) {
                margin-left: $gap-3 / 2;
            }

            .box:nth-child(5) {
                margin-bottom: 0;
            }
        }
    }
}