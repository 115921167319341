.home-page {
    margin: 0px;
    padding: 0;
    width: 100%;
    max-width: none;
    display: block;

    .home-row {
        display: block;

        .home-cell {
            max-width: 600px;
            margin: 0px auto;
            padding: $gap-4;
            text-align: center;

            h1 {
                line-height: 1.5;
                margin-top: $gap-3;
                margin-bottom: $gap-3;
            }

            img {
                width: 80%;
                max-width: 350px;
                margin-top: $gap-4;
            }

            p {
                display: flex;
                align-items: center;
                line-height: 2;
                min-height: 150px;
            }

            .small-links {
                text-align: left;
                font-size: $font-size-2;
                padding-left: $gap-2;
                margin-bottom: $gap-1;
            }

            form {
                display: grid;
                grid-template-columns: 1fr 70px;
                margin-bottom: $gap-7;

                input {
                    display: inline;
                }

                input[type=text] {
                   border-right-width: 0px;
                   border-top-right-radius: 0px;
                   border-bottom-right-radius: 0px;
                }

                input[type=submit] {
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    background-color: $green;
                    color: white;
                    margin: 0;
                }
            }
        }
    }

    .home-1 {
        background: $purple-2;
    }

    .home-2 {
        background-color: white;
    }

    .home-3 {
        background-color: $purple-1;

        .home-cell {
            img {
                max-width: 250px;
                width: 50%;
            }
        }
        
    }
}

@media (min-width: 900px) {
    .home-page {
        .home-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: $gap-5;

            .home-cell {
                padding-top: $gap-6;
                padding-bottom: $gap-6;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                * {
                    max-width: 500px;
                }
            }

            .home-cell:first-child {
                margin-right: 0px;
            }

            .home-cell:last-child {
                margin-left: 0px;
            }
        }

        .home-1 {
    
            .site-search {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;

                .small-links, form {
                    width: 90%;
                }
            }
        }

        .home-3 {
            .home-cell:first-child {
                grid-column: 2;
                grid-row: 1;
                margin-left: 0px;
                margin-right: auto;
            }

            .home-cell:last-child {
                grid-column: 1;
                grid-row: 1;
                margin-left: auto;
                margin-right: 0px;
            }
        }
    }
}