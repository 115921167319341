.api-page {

    .box {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;

        p {
            line-height: 1.8;
            margin-bottom: $gap-2;
        }

        p.endpoint {
            margin: $gap-3 0 $gap-4 0;
        }

        iframe {
            width: 100%;
            height: 500px;
            max-width: 800px;
            border: 1px solid $purple-2;
        }
    }
}