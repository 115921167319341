.help-page {

    max-width: 800px;

    h2 {
        font-size: $font-size-7;
        margin-bottom: $gap-3;
    }

    p {
        line-height: 1.8;
        margin-bottom: $gap-2;
    }
}