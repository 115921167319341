.sequence-job-page, .structure-job-page {
    
    .box {
        text-align: left;
        position: relative;
    }

    h1 {
        text-align: center;
    }

    .label {
        font-weight: 300;

        span {
            font-weight: 600;
        }
    }

    .status {
        font-size: $font-size-6;
        font-weight: 300;
        margin-bottom: $gap-2;
    }

    h2 {
        display: inline-block;
        width: 50%;
    }

    .sequence-option {
        display: none;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        right: $gap-3;
        height: 45px;

        input {
            width: 50px;
            padding: $gap-1;
            margin-right: $gap-3;
        }

        input::placeholder {
            color: #c0c0c0;
            font-weight: 300;
        }

        label {
            font-size: $font-size-2;
            margin-left: $gap-2;
        }

        .react-toggle--checked {
            .react-toggle-track {
                background-color: $purple-4;
            }

            .react-toggle-thumb {
                border-color: $purple-4;
            }
        }
    }

    p.warning {
        font-size: $font-size-2;
        margin-top: $gap-3;
        color: $gray-4;
        line-height: 1.5;
        display: flex;
        align-items: center;
    }

    .sequence {
        font-family: monospace;
        font-weight: light;
        word-break: break-all;
        font-size: $font-size-2;
        margin-bottom: $gap-2;

        .loc {
            display: inline-grid;
            width: 7.5px;
            background: none;
        }

        .upper, .lower {
            font-family: monospace;
        }

        .lower {
            text-transform: uppercase;
            opacity: 0.7;
        }

        .upper {
            font-weight: bold;
        }

        .index {
            white-space: nowrap;
            color: $purple-3;
        }
    }

    .sequences, .structures {
        .predicted-sequence, .predicted-structure {
            margin: $gap-5 0;

            .info {
                display: flex;
                align-items: flex-end;

                .family {
                    margin-right: $gap-3;
                    line-height: 1;
                    font-weight: 600;
                }

                .probability {
                    font-size: $font-size-2;
                }
            }

            .sequence {
                color: $gray-4;

                .upper {
                    color: $gray-9;
                }
            }
        }

        
    }

    .structures {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gap-4;

        .predicted-structure {
            border: 1px solid $gray-3;
            padding: $gap-4 $gap-3;
            margin: 0;
            margin-bottom: $gap-2;
            margin-right: $gap-2;

            .info {
                margin-bottom: $gap-2;
                font-weight: 300;
            }

            .site {
                display: flex;
                font-size: $font-size-2;

                .residue {
                    margin-right: $gap-1;
                }
            } 
        }
    }

    @media (min-width: 400px) {
        .status {
            font-size: $font-size-7;
            margin-bottom: $gap-3;
        }
    
        .sequence {
            margin-bottom: $gap-3;
        }
    }

    @media (min-width: 600px) {
        .sequence-option {
            display: inline-flex;
        }
    }
}