.predict-page {

    .predict-grid {
        display: grid;
        gap: $gap-3;

        .box {
            overflow: visible;
        }
    }

    h2 {
        margin-bottom: $gap-3;
    }

    textarea {
        font-family: monospace;
        height: 250px;
        margin-bottom: $gap-3;
    }

    textarea.errored {
        background-color: transparentize($red, 0.8);
        color: transparentize($red, 0.2);
    }

    .file-upload {
        border: 1px dashed $gray-3;
        padding: $gap-6 $gap-5;
        color: $gray-6;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: $gap-3;
        //height: 210px;
        height: 250px;

        img {
            width: 50px;
            display: block;
            margin-bottom: $gap-3;
        }

        input {
            display: none;
        }
    }

    .toggles {
        text-align: left;
        margin: -$gap-2;
        margin-bottom: $gap-3 - $gap-2;

        .toggle {
            display: flex;
            align-items: center;
            display: inline-flex;
            margin: $gap-2;

            label {
                font-size: $font-size-2;
                margin-left: $gap-2;
            }
        }
    }

    .options {
        .families-select {
            margin-bottom: $gap-2;
        }
    }

    @media (min-width: 400px) {
        h2 {
            margin-bottom: $gap-4;
        }

        .options {
            display: grid;
            grid-template-columns: 1fr 100px;
            gap: $gap-2;

            .families-select {
                margin-bottom: 0;
            }

            input {
                height: 100%;
                margin: 0;
                padding: 0 $gap-2;
            }
        }
    }

    @media (min-width: 900px) {
        .predict-grid {
            grid-template-columns: 1fr 1fr;
        }
    }
}