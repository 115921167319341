.site-result {
    font-size: $font-size-2;
    cursor: pointer;
    display: block;
    color: inherit;

    .box {
        padding: 0;
        text-align: left;
        display: flex;
        background-color: white;
        border-left: 10px solid $purple-4;

        .site-id {
            padding: $gap-2;
            font-size: $font-size-5;
            border-right: 1px solid $purple-3;
            min-width: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
        }

        .site-info {
            flex-grow: 5;
            font-size: $font-size-2;

            .pdb {
                border-bottom: 1px solid $purple-3;
                padding: $gap-2;

                span {
                    font-style: italic;
                }
            }

            .residues {
                display: flex;
                padding: $gap-2;
                align-items: center;

                .family {
                    margin-right: $gap-2;
                    font-weight: 500;
                    font-size: $font-size-3;
                }

                .residue {
                    margin-right: $gap-1;
                }
            }
        }
    }
}

.site-result:hover {

    .box {
        background-color: $purple-2;
        border-left-color: $purple-5;
    }
    
}

@media (min-width: 600px) {
    .site-result {
        font-size: $font-size-3;
    }
}