.search-page {
    max-width: 900px;

    .box {

        h2 {
            margin-bottom: $gap-3;
        }

        .search-input {
            display: grid;
            position: relative;
            z-index: 0;

            select {
                font-size: $font-size-2;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            input {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-top-width: 0;
                margin-bottom: $gap-3;
            }

            .remove-term {
                position: absolute;
                right: 5px;
                margin: 0;
                padding: 0;
                line-height: 1;
                border-radius: 100%;
                padding: 3px 6px;
                background-color: transparentize($red, 0.3);
                bottom: 27.5px;
            }
        }

        .search-buttons {
            display: flex;
            margin: $gap-4 0 0 0;
            justify-content: center;

            button {
                margin: 0 $gap-1;
                font-size: $font-size-2;
            }

            button:first-child {
                font-weight: 400;
                background-color: transparentize($purple-4, 0.1);
            }
        }

        textarea {
            font-family: 'Courier New', Courier, monospace;
            height: 300px;
            margin-bottom: $gap-3;
        }

        select.expect {
            margin: $gap-2 auto;
            max-width: 300px;
            
        }

        button {
            margin: $gap-5 auto $gap-3 auto;
        }
    }

}

@media (min-width: 500px) {
    .search-page {

        .box {

            h2 {
                margin-bottom: $gap-4;
            }

            .search-input {
                grid-template-columns: 1fr 1fr;

                select {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: $border-radius-1;
                    font-size: inherit;
                    margin-bottom: $gap-3;
                }

                input {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-top-right-radius: $border-radius-1;
                    border-left-width: 0;
                    border-top-width: 1px;
                }
            }

            .search-buttons {
                display: flex;
                margin: $gap-4 0;
                justify-content: center;
    
                button {
                    margin: 0 $gap-3;
                    font-size: $font-size-3;
                }
            }

            textarea {
                margin-bottom: $gap-6;
            }
    
            select.expect {
                margin: $gap-3 auto;
            }
    
            button {
                margin: $gap-6 auto $gap-4 auto;
            }
        }
    }
}