.pdb-page {

    .box {
        width: 100%;
        margin: auto;
        max-width: calc(100vw - #{2 * $gap-3});
        text-align: left;

        h1 {
            text-align: center;
        }

        h2 {
            font-weight: 600;
            font-size: $font-size-5;
            margin-bottom: $gap-4;
        }
    }


    .heading {
        padding: $gap-3;

        h1 {
            font-size: $font-size-6;
        }
    }

    .chains {
        padding: $gap-4;
        text-align: left;
        width: 100%;
        overflow: hidden;
        overflow-y: scroll;
        max-height: 700px;
        //text-align: center;

        .pdb-chain {
            width: 100%;
            margin-bottom: $gap-2;

            h3 {
                font-weight: 600;
            }

            .site-count {
                font-size: $font-size-1;
            }

            .sequence {
                overflow-x: scroll;
                font-size: $font-size-1;
                color: $gray-4;
                padding-bottom: $gap-3;

                span {
                    font-family: monospace;
                    white-space: pre;
                }

                .binding {
                    color: $gray-10;
                    font-weight: bold;
                }
            }
        }
    }

    .atoms {
        display: grid;
        padding: $gap-2;
        grid-template-columns: 1fr;
        gap: $gap-5;
        overflow-y: scroll;
        max-height: 700px;
        text-align: center;

        .identified {
            .all-zincs {
                width: 75%;
                margin: auto;

                .atom {
                    display: inline-block;
                    margin-right: $gap-1;
                    line-height: 2;
                    font-weight: 400;
                }
            }
        }

        table {
            border-collapse: separate;
            border-spacing: $gap-2;
            margin-top: -$gap-2;
            margin: auto;

            tr {

                td:nth-child(1) {
                    font-weight: 500;
                }

                td:nth-child(2) {
                    font-weight: 300;
                    text-align: left;
                }
            }
        }
    }

    .sites {
        text-align: left;
        padding: $gap-4;
        overflow-y: scroll;
        max-height: 700px;

        .zinc-sites {
            border: 1px solid $purple-3;
            border-left: 0;

            .site {
                margin-left: 0;
            }
        }
    }
}

@media (min-width: 400px) {
    .pdb-page {
        .atoms.split {
            grid-template-columns: 1fr 1fr;
            gap: $gap-2;
        }
    }
}

@media (min-width: 600px) {
    .pdb-page {
        .box {
            h2 {
                font-size: $font-size-6;
            }
        }

        .heading {
            padding: $gap-4;

            h1 {
                font-size: $font-size-7;
            }
        }

        .chains {

            .pdb-chain {
                margin-bottom: $gap-3;

                .site-count {
                    font-size: $font-size-2;
                }

                .sequence {
                    font-size: $font-size-2;
                }
            }
        }
        
    }
}

@media (min-width: 800px) {
    .pdb-page {
        
        .properties {
            .full {
                grid-column: inherit;
            }
        }
    }
}

@media (min-width: 1032px) {
    .pdb-page {
        
        .box {
            max-width: 1000px;
        }
    }
}