* {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	box-sizing: border-box;
	text-decoration: none;
	background-color: inherit;
	background-image: none;
	list-style: inherit;
	color: inherit;
	list-style: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	font-family: "Source Sans Pro", Arial;

}
