$hyperlink-blue: hsl(206, 92%, 46%);

$font-size-1: 10px;
$font-size-2: 12px;
$font-size-3: 14px;
$font-size-4: 16px;
$font-size-5: 18px;
$font-size-6: 20px;
$font-size-7: 24px;
$font-size-8: 30px;
$font-size-9: 36px;
$font-size-10: 48px;
$font-size-11: 60px;
$font-size-12: 72px;

$gap-1: 5px;
$gap-2: 10px;
$gap-3: 16px;
$gap-4: 24px;
$gap-5: 36px;
$gap-6: 48px;
$gap-7: 72px;

$border-radius-1: 3px;
$border-radius-2: 10px;

$gray-1: hsl(216, 33%, 97%);
$gray-2: hsl(214, 15%, 91%);
$gray-3: hsl(210, 16%, 82%);
$gray-4: hsl(211, 13%, 65%);
$gray-5: hsl(211, 10%, 53%);
$gray-6: hsl(211, 12%, 43%);
$gray-7: hsl(209, 14%, 37%);
$gray-8: hsl(209, 18%, 30%);
$gray-9: hsl(209, 20%, 25%);
$gray-10: hsl(210, 24%, 16%);

$green: hsl(168, 34%, 44%);

$red: hsl(350, 82%, 61%);

$cream: #fdfdfd;

$navheight: 50px;

$purple-1: hsl(342, 20%, 97%);
$purple-2: hsl(312, 15%, 94%);
$purple-3: hsl(246, 38%, 90%);
$purple-4: hsl(281, 34%, 35%);
$purple-5: hsl(283, 31%, 25%);
$purple-6: hsl(281, 35%, 20%);
$purple-7: hsl(282, 30%, 11%);

$box-shadow-1: 0 2px 5px 0 rgba(0,0,0,0.23), 0 2px 10px 0 rgba(0,0,0,0.23);
$box-shadow-2: 0 3px 6px 0 rgba(0,0,0,0.2);
$internal-shadow: inset 0 1px 2px rgba(27,31,35,0.075);

$select-box-width: 3rem;
$select-arrow-width: 1rem;
$select-arrow-height: 0.5rem;
$select-line-margin: 0.5rem;