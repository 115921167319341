html {
    color: $gray-8;
    font-size: $font-size-3;
    background-color: $gray-2;
}

#zincbind {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1;
    width: calc(100vw - #{2 * $gap-3});
    max-width: 1200px;
    margin: $gap-3 auto;
    display: grid;
    grid-gap: $gap-3;
}

.main-fill {
    height: calc(100vh - #{$navheight} - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
}

h1 {
    font-size: $font-size-8;
    font-weight: bold;
}

h2 {
    font-size: $font-size-7;
}

a {
    color: hsl(208, 100%, 43%);
}

input, select, button, textarea {
    display: block;
    background-color: white;
    padding: $gap-2;
    font-size: $font-size-3;
    border: 1px solid $purple-3;
    border-radius: $border-radius-1;
    width: 100%;
    box-shadow: $internal-shadow;
}

select {
	background-image: linear-gradient(45deg, transparent 50%, $gray-3 50%),
            		  linear-gradient(135deg, $gray-3 50%, transparent 50%),
                      linear-gradient(to right, $gray-3, $gray-3);
	background-position: calc(100% - #{$select-box-width / 2}) calc(50%),
	                     calc(100% - #{$select-box-width / 2} + #{$select-arrow-width / 2}) calc(50%),
						 calc(100% - #{$select-box-width}) calc(100% - #{$select-line-margin});
	background-size: $select-arrow-width / 2 $select-arrow-height,
	                 $select-arrow-width / 2 $select-arrow-height,
					 1px calc(100% - #{2 * $select-line-margin});
	background-repeat: no-repeat;
	background-color: $cream;
	padding-right: $select-box-width + 0.5rem;
	cursor: pointer;
}

input[type=submit], button {
    width: auto;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    background-color: $purple-5;
    color: #fff;
    border: none;
    font-weight: 600;
}

.error {
    color: $red;
    font-size: $font-size-3;
    text-align: left;
    margin-left: $gap-1;
    margin-bottom: $gap-1;
}


@media (min-width: 600px) {
    html {
        font-size: $font-size-4;
    }

    h1 {
        font-size: $font-size-9;
    }

    h2 {
        font-size: $font-size-8;
    }

    input, select {
        font-size: $font-size-4;
    }
}